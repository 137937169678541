
div.ui-datebox-inline.ui-datebox-inline-has-input {
  top: inherit !important;
}

.dropdown-month-field {
  padding: 0px !important;
  color: #7a7b7b;
  margin: 0px !important;
}

.horizontal-day-field {
  padding: 0px !important;
}

.datebox-input {
  display: none;
}

.ui-datebox-header {
  display: none;
}

.ui-datebox-sliderow {
  margin-bottom: 0px;
}

.ui-input-text {
  display: none;
}

.ui-datebox-container {
  width: 100% !important;
  border: none !important;
}

.ui-datebox-container .ui-datebox-header {
  display: none;
}

.ui-datebox-container .ui-datebox-slide {
  width: 100% !important;
}

.ui-datebox-container .ui-datebox-slide .ui-datebox-sliderow {
  width: 100% !important;
  margin-bottom: 0px;
}

.ui-datebox-container .ui-datebox-slideday {
  padding: 0px;
  font-size: 18px;
  font-family: 'Roboto';
  width: 45px;
  text-align: center;
  height: 48px;
}

.ui-datebox-container .ui-button {
  color: #7a7b7b !important;
  background: #eceff1 !important;
  border: none !important;
  border-radius: 0px !important;
}

.ui-datebox-container .ui-button.ui-state-active {
  color: #f44336 !important;
  background: #eceff1 !important;
  border-bottom: 4px solid #f44336 !important;
}

.ui-datebox-slidewday {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}

.ui-loader {
  display: none;
}

.select-wrapper input.select-dropdown {
  height: 48px;
  margin-bottom: 0px;
  font-size: 20px;
  border-bottom: none;
  width: 100%;
  text-align: center;
  padding-left: -.5rem;
}

.dropdown-month-field .select-wrapper span.caret {
  /*display: none !important;*/
  padding-right: .5rem;
}

.dropdown-month-field .dropdown-content {
  width: 120px !important;
  max-width: 120px !important;
}

.dropdown-month-field .select-wrapper span.caret {
  padding-right: .5rem;
}

.ui-widget-content {
  background: none !important;
}

.dropdown-month-field {
  background-color: #ffffff;
}

.dropdown-month-field .dropdown-content li > a, .dropdown-content li > span {
  color: #7a7b7b !important;
}
